import React from "react";
import "../assets/styles/NotFound.css";

const NotFound = () => (
    <div className="container">
    <div className="no_page">
        <h1>Not Found</h1>
    </div>
  </div>
);

export default NotFound;
